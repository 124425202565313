var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"property-suggestions"}},[(_vm.inSalabamInfoBox && _vm.inSalabamInfoBox.category && _vm.inSalabamInfoBox.category.code)?_c('div',{staticClass:"sidebarBlock"},[_c('SuperInSlider',{attrs:{"category":_vm.inSalabamInfoBox.category,"skip":[],"defaultPosts":[{
              abstract:_vm.inSalabamInfoBox.abstracy,
              backgroundImage:_vm.inSalabamInfoBox.imgSrc,
              category:_vm.inSalabamInfoBox.category,
              //countryCode: 'IT'
              credits:_vm.inSalabamInfoBox.credits,
              //geo:Object
              inSalabamId: (_vm.inSalabamInfoBox.inSalabamId).toString(),
              label: 'in|' + _vm.site.brand + ' ' + _vm.inSalabamInfoBox.title,
              name:_vm.inSalabamInfoBox.title,
              padImage:_vm.inSalabamInfoBox.imgSrc,
              super:_vm.inSalabamInfoBox.super,
              title:_vm.inSalabamInfoBox.title,
              //type: 'inSalabam'
          }]}})],1):_vm._e(),(_vm.suggestions && _vm.suggestions.length)?_c('div',[_vm._m(0),_c('div',{staticClass:"sidebarBlock"},[_c('div',{staticClass:"slick-wrapper",on:{"wheel":_vm.scrollSlider}},[_c('div',{staticClass:"slick-container"},[_c('Slick',{ref:"slick_suggestion",attrs:{"id":"slick_suggestion","options":_vm.slickOptions},on:{"init":_vm.slick__handleInit}},_vm._l((_vm.suggestions),function(property,index){return _c('div',{key:'slide-suggest-'+index,on:{"click":_vm.changeProperty}},[_c('PropertyPad',{attrs:{"property":property,"index":index,"showLite":false}})],1)}),0),_c('span',{staticClass:"slick-arrow",attrs:{"variant":"light","id":"slick-prev-suggestions"}},[_c('SvgChevronLeft')],1),_c('span',{staticClass:"slick-arrow",attrs:{"variant":"light","id":"slick-next-suggestions"}},[_c('SvgChevronRight')],1)],1)])])]):(!_vm.noResults)?_c('Spinner'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebarBlock"},[_c('p',{staticClass:"small text-center mb-0"},[_vm._v("Scopri le nostre proposte qui sotto")])])}]

export { render, staticRenderFns }