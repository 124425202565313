<template>
    <div class="super-in-slider" v-if="!loading && posts.length > 0">
        <div class="pad">
            <Slick ref="slick-in-salabam-buttons" :options="slickOptions">
                <div class="slick-item" v-for="(singlePost,index) in posts.filter(function(p){ return !p.super })" v-bind:key="_uid+'_super_'+index" @click="inSalabam(singlePost)">
                    <div class="pad-item" :style="'background-image:url('+singlePost.backgroundImage+')'">
                        <div class="captions">
                            <span class="name">In|{{site.brand}}</span>
                            <span class="info">{{singlePost.title}}</span>
                            <div class="cta">
                                <b-button variant="cta">Scopri tutte le strutture</b-button>
                            </div>
                        </div>
                    </div>
                    <div class="scopri">Vedi tutti gli IN|{{site.brand}}</div>
                </div>
            </Slick>
        </div>
        
        <div class="arrows" v-if="posts.length > 1">
            <span class="slick-arrow" id="slick-prev-button">
                <ChevronLeft />
            </span>
            <span class="slick-arrow" id="slick-next-button">
                <ChevronRight />
            </span>
        </div>
    </div>
    <div class="super-in-slider loading" v-else-if="loading">
        <Spinner />
    </div>
</template>

<script>
import Api from '../../../api'
import Slick from 'vue-slick';
import Spinner from '../../atoms/spinner'
import ChevronLeft from '../../svg/chevron-left'
import ChevronRight from '../../svg/chevron-right'

export default {
    name: 'super-in-slider',
    components: {
        Slick,
        Spinner,
        ChevronLeft,
        ChevronRight,
    },
    props: {
        category: {
            type: Object,
            default: () => { return {code: '', label: ''} },
        },
        skip: {
            type: Array,
            default: () => new Array()
        },
        defaultPosts: {
            type: Array,
            default: () => new Array()
        },
    },
    data() {
    return {
            loading: true,
            posts: [],
            site: this.$config.site,
            slickOptions: {
            autoplay: false,
            draggable:false,
            infinite: false,
            prevArrow: '#slick-prev-button',
            nextArrow: '#slick-next-button',
            },      
        }
    },
    mounted()
    {
        if (this.defaultPosts.length > 0)
        {
            this.posts = this.defaultPosts
            this.loading = false
            return
        }

        let _this = this
        _this.loading = true
            Api.getInSalabam({
                categoryCode: this.category.code,
            })
            .then((data) => {
                if(data.results){

                    let inSalabams = (data.results).filter(function(inSalabam)
                    {
                        return !_this.skip.includes(parseInt(inSalabam.inSalabamId))
                    })
                    _this.posts = inSalabams


                    if(_this.$gtm){

                      _this.$gtm.trackEvent({
                        event: 'customEvent',
                        category: 'inSalabam',
                        action: 'categorySliderLoaded',
                        label: _this.category.code,
                        value: 0,
                      });

                    }

                }
                else if(data.inSalabam)
                {
                    _this.posts = [data.inSalabam]//inSalabams
                }
                else
                {
                    //_this.posts = inSalabams
                }
                _this.loading = false
            })
    },
    methods:{
        inSalabam(inSalabam)
        {

            if(this.$gtm) this.$gtm.trackEvent({
              event: 'customEvent',
              category: 'inSalabam',
              action: 'categorySliderClick',
              label: inSalabam.category.code,
              value: inSalabam.name,
            });

            if(window.clarity) window.clarity("set", "inSalabam", "categorySliderClick")

            this.mixinSendMutation('setGeo',inSalabam.geo)
            this.mixinGoTo('in-' + this.site.brand.toLowerCase(), {
              inSalabamId: inSalabam.inSalabamId,
              inSalabamTitle: (inSalabam.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
              setGeo: true,
            })

        }
    }
}
</script>

<style lang="scss">
    
    
    
    

    .super-in-slider
    {
        .pad
        {

        }
        .pad-item
        {
            background-size: cover;
            background-position: center center;
            border-radius: $border-radius-lg;
            overflow: hidden;
            position:relative;
            cursor: pointer;
            &:after
            {
                content:"";
                background:black;
                opacity:0.6;
                position:absolute;
                left:0;
                top:0;
                width:100%;
                height:100%;
                z-index: 5;
                transition: $transition-fade;
            }
            &:hover::after
            {
                opacity:0.4;
            }
            .captions
            {
                color:$white;
                position:relative;
                z-index: 10;
                padding:20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            .name
            {
                font-size: 14px;
            }
            .info
            {
                font-weight: 600;
                font-size: 16px;
            }
            .cta
            {
                width:100%;
                text-align: center;
                margin-top:20px;
            }
            @include media-breakpoint-up(md)
            {
                .captions
                {
                    padding:30px;
                }
                .name
                {
                    font-size: 16px;
                }
                .info
                {
                    font-size: 22px;
                }
            }
        }
        .scopri
        {
            @include customize-color("cta");
            text-decoration: underline;
            text-align: right;
            margin-top: 8px;
            cursor:pointer;
            @include media-breakpoint-up(md)
            {
                margin-top: 18px;
            }
        }
        .arrows
        {
            text-align: right;
            .slick-arrow
            {
                margin:0 4px;
                border-radius: 50%;
                width:38px;
                height:38px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor:pointer;
                svg
                {
                    width:20px;
                    height:auto;
                }
            }
            margin: 8px 0;
            @include media-breakpoint-up(md)
            {
                margin: 18px 0;
            }
        }
    }

    .customized
    {
        .in-salabam-buttons .btn
        {
        }
    }
</style>