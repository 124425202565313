<template>
    <div>
        <PinnedMessage/>
        <div id="sidebar-detail">
            <div class="sidebar-detail-inner">
                

                <div class="sidebarBlock" v-if="!loading">
                    <Reviews :size="'small'"/>
                </div>

                <div class="sidebarBlock" v-if="!loading && enableSuggestions == 1 ">

                    <PropertySuggestInSalabam v-if="inSalabamInfoBox && inSalabamInfoBox.category"
                    :category="inSalabamInfoBox.category"
                    :property_id="parseInt(property_id)"
                    :inSalabamCode="inSalabamInfoBox.inSalabamCode"
                    :inSalabamId="inSalabamInfoBox.inSalabamId"
                    />

                    <!--<PropertySuggestions v-else/>-->
        
                </div>
                
                <!--
                <div class="sidebarBlock">
                    <div class="panel">
                        <div class="top">
                            <img :src="avatarSrc" />
                            <div>
                                <p class="name">{{displayName}}</p>
                                <p v-if="availability > 0" class="credit">Credito Welfare € {{availability}}</p>
                            </div>
                        </div>
                        <div class="bottom">
                            <p>
                                Per questa struttura ti mostriamo sia le camere acquistabili con il tuo
                                credito welfare sia quelle che al momento non sono da te acquistabili
                                causa credito insufficiente.
                            </p>
                        </div>
                    </div>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import PinnedMessage from '../../blocks/common/pinnedMessage.vue'
import Reviews from '../../blocks/property/property-reviews'
//import PropertySuggestions from '../../blocks/property/property-suggestions'
import PropertySuggestInSalabam from '@/components/blocks/property/property-suggest-inSalabam'

export default {
    name: 'sidebar-detail',
    components:
    {
      PinnedMessage,
      Reviews,
      //PropertySuggestions,
      PropertySuggestInSalabam,
    },
    props:
    {
        resultsFiltered: Array,
        inSalabamInfoBox: Object,
    },
    computed: {
        /*
        geo: {
            get() { return this.$store.state.geo },
            set(value) { this.mixinSendMutation('setGeo',value) }
        }
        */
    },
    data() {
        return {
            loading: true,
            property_id: this.$route.params.propertyId,
            property: {},
            availability: Vue.prototype.$config.integration.availability,
            displayName: Vue.prototype.$config.integration.displayName,
            avatarSrc: Vue.prototype.$config.integration.avatarSrc,
            enableSuggestions: Vue.prototype.$config.guiSettings.enableSuggestions,
        }
    },
    mounted()
    {
        let _this = this,
            property_id = _this.property_id,
            property = {}

        property = _this.resultsFiltered.filter(function(p){
            return p.property_id == property_id
        })
        _this.property = property[0]
        _this.loading = false
    },
    methods:
    {         
    }, 
}
</script>

<style lang="scss">
    
    
    
    

    #sidebar-detail
    {
        margin-top:2.25rem;


        border-top:1px solid $medium-gray;
        border-left:1px solid $medium-gray;
        border-bottom:1px solid $medium-gray;
        border-top-right-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        overflow:hidden;


        position:relative;
        border-radius: $border-radius-lg;
        &:before
        {
            content:"";

            @include customize-background-color("primary");
            @include customize-gradient(vertical, important);

            position:absolute;
            top:0;
            right:0;
            height:100%;
            width: 100%;
            border-top-right-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            z-index: 1;
        }
        .sidebar-detail-inner
        {
            background-color:$light-gray;
            display:block;
            position:relative;
            width:100%;
            height:100%;
            background-size:cover;
            background-position:center center;
            z-index: 2;
            width:calc(100% - 10px);
            transition:$transition-fade;
            border-top-right-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            margin-right:10px;

            padding:$grid-gutter-width/4;
        }



        @include media-breakpoint-up(xl)
        {
            margin-top:50px;
        }
        .property-pad
        {
            max-width:370px;
            margin:0 auto;
            @include media-breakpoint-up(lg)
            {
                max-width: 100%;
            }
        }
        .panel
        {
            background:$tertiary;
            width:100%;
            box-shadow: $box-shadow;
            border-radius:$border-radius;

            p
            {
                margin-bottom:0;
                font-weight:normal;
            }

            .top
            {
                padding:$grid-gutter-width/3;
                display:flex;
                align-items: center;
                padding-bottom:$grid-gutter-width/3;
                border-bottom:1px solid $white;
                img {
                    width:44px;
                    height:44px;
                    margin-right:$grid-gutter-width/3;
                }
                p
                {
                    font-size:19px;
                }
                .name
                {
                    color:$white;
                    font-size: 16px;
                }
                .credit
                {
                    @include customize-color("secondary");
                    font-size: 14px;
                }
            }
            .bottom
            {
                padding:$grid-gutter-width/3;
                color:$smooth-black;
                font-size:14px;
            }
        }
        @include media-breakpoint-up(xl)
        {
            .panel .top,
            .panel .bottom
            {
                padding:$grid-gutter-width/3*2;
            }
            .panel .top
            {
                .name
                {
                    font-size: 18px;
                }
                .credit
                {
                    font-size: 18px;
                }
            }
            .panel .bottom
            {
                padding:$grid-gutter-width/3*2;
                font-size:16px;
            }
        }
    
        //restyle vadobay:
        .sidebar-block-title
        {
            @include customize-bordered-element("secondary");
            padding-top:0;
            padding-bottom:0;
            .small
            {
                font-weight: 100;
            }
        }

        @include media-breakpoint-up(lg)
        {
            .sidebar-detail-inner
            {
                padding:$grid-gutter-width/3 $grid-gutter-width/2;
            }
        }

        .sidebarBlock:last-child
        {
            margin-bottom:0;
        }
    }
</style>