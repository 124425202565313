<template>
<div id="property-reviews" :data-size="size">
    <div v-if="reviews && (reviews.length > 0)">
        
        <div class="sidebar-block-title">
            <h5 class="property-reviews__title">Recensioni</h5>
            <p class="small">Estratto di {{totalReviews}} recensioni verificate</p>
        </div>

        <div class="slick-wrapper">
            <Slick
                ref="slick-reviews"
                :options="slickOptions"
                @afterChange="reviewsSlickHandleAfterChange"
                @swipe="reviewsSlickHandleSwipe"
            >
                <div v-for="(value,index) in reviews" v-bind:key="index">
                    <div class="review" v-b-modal="'review-'+size+'-'+index">
                        <div class="review__icons">
                            <img class="review__avatar" :src="value.avatar" />
                            <span class="review__rating">{{value.rating}}/5</span>
                        </div>
                        <!--<div class="review__stars">
                            <Stars :hotelRating="parseFloat(value.rating)" />
                        </div>-->
                        <div class="review__content">
                            <div class="small">
                                <b>{{value.user}}</b> {{value.data}}
                                <br>
                                <i>{{value.verification_source}}</i>
                            </div>
                            <h5 class="review__title">{{value.title}}</h5>
                            <p class="review__text">{{value.text}}</p>
                        </div>
                    </div>
                    <b-modal :id="'review-'+size+'-'+index" hide-footer :title="value.title" @change="reviewsModalOpened">
                        <b-container fluid class="mt-4 mb-4">
                            <div class="small">
                                <b>{{value.user}}</b> - {{value.data}}
                                <br>
                                <i>{{value.verification_source}}</i>
                                <br><br>
                            </div>
                            <p>{{value.text}}</p>
                        </b-container>
                    </b-modal>
                </div>
            </Slick>
            <div class="reviews__dots">
                <span variant="light" class="slick-arrow" :id="size+'-slick-prev-review'"><SvgChevronLeft /></span>
                <span variant="light" class="slick-arrow" :id="size+'-slick-next-review'"><SvgChevronRight /></span>
            </div>
        </div>
    </div>
    <div v-else-if="reviews && (reviews.length == 0)">
        <small class="ml-4">Non ci sono ancora recensioni per questa struttura.</small>
    </div>
    <Spinner v-else />
</div>    
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import { mapGetters } from 'vuex'
import Slick from 'vue-slick'
import Spinner from '../../atoms/spinner'
import SvgChevronRight from '../../svg/chevron-right'
import SvgChevronLeft from '../../svg/chevron-left'

export default {
    name: 'property-reviews',
    components:
    {
        Slick,
        Spinner,
        SvgChevronLeft,
        SvgChevronRight,
    },
    props:
    {
        size: String,
    },
    computed: mapGetters({
        property: 'getPropertyDetail',
    }),
    data() {
        return {
            reviews: [],
            totalRating: 0,
            totalReviews: 0,
            slickOptions: {
                autoplay: (this.$config.guiSettings.autoplaySpeedReviews != 0),
                autoplaySpeed: this.$config.guiSettings.autoplaySpeedReviews,
                slidesToShow: (this.size == 'small' ? 1 : 3),//1 nella sidebar, 3 in fondo a pagina dettaglio
                slidesToScroll: 1,
                infinite: false,
                dots:false,
                prevArrow: '#'+this.size+'-slick-prev-review',
                nextArrow: '#'+this.size+'-slick-next-review',
                responsive: [{
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }]
            },
        }
    },
    
    watch: {
        property: function () {
            this.getPropertyReviews()
        }
    },
    
    methods: {
        getPropertyReviews()
        {
            let _this = this
            _this.reviews = []
            Api
                .getReviews({
                        property_id: parseInt(_this.property.property_id),
                        token: Vue.prototype.$config.token
                    })
                .then((data) => {
                    let sum = 0
                    
                    _this.reviews = []
                    _this.totalRating = 0
                    _this.totalReviews = 0
                    
                    if(data) {
                        data.reviews.forEach(review => {
                            sum = sum + parseFloat(review.rating)
                        });
                        _this.reviews = data.reviews
                        _this.totalRating = sum / (data.reviews.length)
                        _this.totalReviews = data.count
                    }
                })
        },

        reviewsModalOpened(){
            if(this.$gtm) this.$gtm.trackEvent({
                event: 'customEvent',
                category: 'userBehaviour',
                action: 'reviewsModalOpened',
            });

            if(window.LogRocket) window.LogRocket.track('reviewsModalOpened')
            if(window.clarity) window.clarity("set", "reviews", "modalOpened")
        },

        // event, slick, currentSlide
        reviewsSlickHandleAfterChange() {

            if(this.$gtm) this.$gtm.trackEvent({
                event: 'customEvent',
                category: 'userBehaviour',
                action: 'reviewsSlickInteraction',
                label: 'change',
            });

            if(window.LogRocket) window.LogRocket.track('reviewsSlickInteractionChange')
            if(window.clarity) window.clarity("set", "reviews", "slickInteractionChange")

        },
        
        // event, slick, currentSlide
        reviewsSlickHandleSwipe() {

            if(this.$gtm) this.$gtm.trackEvent({
                event: 'customEvent',
                category: 'userBehaviour',
                action: 'reviewsSlickInteraction',
                label: 'swipe',
            });

            if(window.LogRocket) window.LogRocket.track('reviewsSlickInteractionSwipe')
            if(window.clarity) window.clarity("set", "reviews", "slickInteractionSwipe")
        },

    },
    mounted()
    {
        this.getPropertyReviews()
    }    
}
</script>

<style lang="scss">
    
    
    
    
    #property-reviews
    {
        .property-reviews__title-wrapper
        {
            @include customize-bordered-element("secondary");
            small
            {
                font-weight: 100;
            }
        }
        .slick-list
        {
            padding-left:0.75rem;
            margin-left:-0.75rem;
            margin-top:2rem;
            .slick-slide
            {
                transition:$transition-base;
            }
            .slick-slide:not(.slick-active)
            {
                opacity:0;
            }
        }
        .property-reviews__title
        {
            font-weight:600;
            font-size:23px;
            color:$black;
            margin:0;
        }

        .reviews__dots
        {
            display:flex;
            justify-content: flex-end;
            margin-top:1rem;
            span
            {
                display:flex;
                justify-content: center;
                align-items: center;
                width:38px;
                height:38px;
                //background:$white;
                //color:$smooth-black;
                border-radius:50%;
                margin-left:10px;
                cursor:pointer;
            }
        }


        .review *
        {
            font-family: "Century Gothic", CenturyGothic, Arial, sans-serif !important;
            color:$black;
            cursor: pointer;
        }
        .review
        {
            background:$white;
            border-radius:$border-radius;
            position:relative;
            margin-top:$grid-gutter-width/2;
            .review__icons
            {
                display: flex;
                align-items:center;
                transform: translateX(-12px) translateY(-16px);
            }
            .review__content
            {
                padding:$grid-gutter-width/2;
                padding-top:0;//$grid-gutter-width/8;
                position:relative;

                height:260px;
                overflow:hidden;
                &:after
                {
                    content:"";
                    position:absolute;
                    bottom:0;
                    height:80px;
                    left:0;
                    width:100%;
                    background: linear-gradient(0deg, $white, transparent 100%);
                    border-bottom:20px solid $white;
                    border-radius:$border-radius;
                }
            }

            .small
            {
                line-height:120%;
                font-size: 12px;
                margin-bottom:12px;
                margin-top:6px;
            }            

            .review__avatar
            {
                width:44px;
                height:44px;
                border:2px solid $white;
                border-radius: 50%;
                z-index: 1;
            }

            .review__rating
            {
                width:50px;
                height:50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                background:$tertiary;
                text-align:center;
                border-radius: 50%;
                color:$white;
                font-weight:600;

                transform: translateX(-4px);
            }

            .review__stars
            {
                position:absolute;
                top:0;
                left:0;
                right:0;
                margin:auto;
                text-align:center;
                z-index: 1;
                transform:translateY(-50%);
            }

            .review__title
            {
                margin-top:0;
            }

            .review__text
            {
                margin-bottom:0;
            }
        }

        &[data-size=small]
        {
            .review
            {
                //margin-left:$grid-gutter-width/3;
            }
            .review__icons
            {
                //transform:none;
            }
        }

        &[data-size=large]
        {
            margin:4rem 0;
            @include media-breakpoint-up(sm)
            {
                .review,
                .reviews__dots
                {
                    margin-right:$grid-gutter-width;
                }
            }
        }

    }
</style>