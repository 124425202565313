<template>
  <div id="property-suggestions">

    <div class="sidebarBlock" v-if="inSalabamInfoBox && inSalabamInfoBox.category && inSalabamInfoBox.category.code" >
        <SuperInSlider
            :category="inSalabamInfoBox.category"
            :skip="[]"
            :defaultPosts="[{
                abstract:inSalabamInfoBox.abstracy,
                backgroundImage:inSalabamInfoBox.imgSrc,
                category:inSalabamInfoBox.category,
                //countryCode: 'IT'
                credits:inSalabamInfoBox.credits,
                //geo:Object
                inSalabamId: (inSalabamInfoBox.inSalabamId).toString(),
                label: 'in|' + site.brand + ' ' + inSalabamInfoBox.title,
                name:inSalabamInfoBox.title,
                padImage:inSalabamInfoBox.imgSrc,
                super:inSalabamInfoBox.super,
                title:inSalabamInfoBox.title,
                //type: 'inSalabam'
            }]"
        />
    </div>

    <div v-if="suggestions && suggestions.length">
      <div class="sidebarBlock">
          <p class="small text-center mb-0">Scopri le nostre proposte qui sotto</p>
          <!--
        <div class="sidebar-block-title">
          <p class="small">
            Chi ha visualizzato <strong>{{property.name}}</strong> ha visualizzato anche:
          </p>
          <h5 class="property-suggestions__title mb-3 d-flex align-items-center" v-b-popover.hover.top="'Gli utenti che hanno effettuato la tua stessa ricerca hanno visualizzato anche queste strutture, simili per tipologia, posizione e servizi offerti'">
            <SvgInfoCircle/>&nbsp;{{title}}
          </h5>
        </div>
        -->
      </div>
      <div class="sidebarBlock">
        <div class="slick-wrapper" @wheel="scrollSlider">
          <div class="slick-container">
            <Slick
                id="slick_suggestion"
                ref="slick_suggestion"
                :options="slickOptions"
                @init="slick__handleInit"
            >
              <div
                  v-for="(property,index) in suggestions"
                  :key="'slide-suggest-'+index"
                  @click="changeProperty"
              >
                <PropertyPad
                    :property="property"
                    :index="index"
                    :showLite="false"
                />
                <!--<a class="pad-wrapper" :data-slide-index="index" @click="clickedPad"></a>-->
              </div>
            </Slick>
            <span variant="light" class="slick-arrow" id="slick-prev-suggestions"><SvgChevronLeft /></span>
            <span variant="light" class="slick-arrow" id="slick-next-suggestions"><SvgChevronRight /></span>
          </div>
        </div>
      </div>
    </div>

    <Spinner v-else-if="!noResults" />

  </div>
</template>

<script>
//import Vue from 'vue'
import Api from '../../../api'
import Slick from 'vue-slick'
import { mapGetters } from 'vuex'
import PropertyPad from '../../blocks/property/property-pad'
import Spinner from '../../atoms/spinner'
//import SvgInfoCircle from './../../svg/info-circle'
import SvgChevronRight from '../../svg/chevron-right'
import SvgChevronLeft from '../../svg/chevron-left'
import SuperInSlider from '@/components/blocks/sidebar/super-in-slider.vue'

export default {
  name: 'property-suggest-inSalabam',
  components:
      {
        Slick,
        Spinner,
        PropertyPad,
        //SvgInfoCircle,
        SvgChevronLeft,
        SvgChevronRight,
        SuperInSlider,
      },
  props:
      {
        category: {
          type: Object,
          default: () => { return {code: '', label: ''} },
        },
        property_id:{
          type: Number,
          default: 0,
        },
        inSalabamCode:{
          type: String,
          default: '',
        },
        inSalabamId:{
          type: Number,
          default: 0,
        }
      },
  computed:{
    ...mapGetters({
      checkin:   'getCheckin',
      checkout:   'getCheckout',
      nights:  'getNights',
      occupancy: 'getOccupancy',
      geo: 'getGeo',
      property: 'getPropertyDetail',
    }),
    title:{
      get(){

        if(this.inSalabamInfoBox && this.inSalabamInfoBox.title) return this.inSalabamInfoBox.title
        return 'Strutture suggerite'

      },
      set(){},
    }
  },
  watch: {
    property: function () {
      this.getPropertySuggestions()
    }
  },
  data() {
    return {
      suggestions: [],
      inSalabamInfoBox: {},
      noResults: false, // nel caso api risponda vuoto (non ritorna mai errore), così tolgo lo spinner
      slickOptions: {
        autoplay: (this.$config.guiSettings.autoplaySpeedSuggestions != 0),
        autoplaySpeed: this.$config.guiSettings.autoplaySpeedSuggestions,
        vertical: true,
        adaptiveHeight: false,
        //verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        prevArrow: '#slick-prev-suggestions',
        nextArrow: '#slick-next-suggestions',
        //adaptiveHeight:true,
        responsive: [{
          breakpoint: 993,
          settings: {
            //adaptiveHeight:false,
            //centerMode: true,
            //slidesToShow: 3,
            //variableWidth: true,
            //vertical: false,
            //verticalSwiping: false,
          }
        }]
      },
      site: this.$config.site,
    }
  },
  methods: {
    getPropertySuggestions()
    {
      let _this = this
      _this.suggestions = []
      Api
          .getPreview({
            checkin: _this.checkin,
            checkout: _this.checkout,
            nights: _this.nights,
            occupancy: _this.occupancy,
            lat: _this.geo.latitude,
            lng: _this.geo.longitude,
            matchLevel: _this.geo.matchLevel,
            geoLabel: _this.geo.label,
            offset: 0,
            searchCounter: 0,

            inSalabamSuggestion:{
              inSalabamId: _this.inSalabamId,
              categoryCode: _this.category.code,
              property_id: _this.property.property_id,
            },

          })
          .then((data) => {

            if(data && data.results.length) {

              _this.suggestions = data.results.filter(property => property.property_id != _this.property_id)

              if(data.inSalabamInfoBox) _this.inSalabamInfoBox = data.inSalabamInfoBox

            }else{
              _this.noResults = true;
            }
          })

    },
    changeProperty()
    {

      if(this.$gtm) this.$gtm.trackEvent({
        event: 'customEvent', // Event type [default = 'interaction'] (Optional)
        category: 'inSalabam',
        action: 'suggestedPropertyClick',
        label: this.category.code,
        value: this.property.name,
      });

      if(window.clarity) window.clarity("set", "inSalabam", "suggestedPropertyClick")

      this.mixinSendMutation('setPropertyDetail', this.property)
      this.mixinGoTo('property', {propertyId: this.property.property_id, propertyName: this.property.name})
    },
    slick__handleInit()
    {
      let slides = document.querySelectorAll('#slick_suggestion .slick-slide'),
          maxHeight = -1

      slides.forEach(function(slide){
        if(slide.clientHeight > maxHeight) {maxHeight=slide.clientHeight}
      })
      slides.forEach(function(slide){
        slide.setAttribute('style','height:'+maxHeight+'px;')
      })
    },
    slick__next() {
      this.$refs.slick_suggestion.next();
    },

    slick__prev() {
      this.$refs.slick_suggestion.prev();
    },
    scrollSlider(event) {
      event.preventDefault()
      event.stopPropagation()
      //let deltaX = event.deltaX
      let deltaY = event.deltaY

      if(deltaY > 10)
      {
        this.slick__next()
      }
      if(deltaY < -10)
      {
        this.slick__prev()
      }

      return false
      //swindow.console.log(deltaY)
    }

  },
  mounted()
  {
    this.getPropertySuggestions()
  }
}
</script>

<style lang="scss">



#property-suggestions
{
  .slick-slide > div
  {
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 100%;
    //padding-top:1rem;
    //padding-bottom:1rem;
  }
  .slick-container
  {
    padding-top:2rem;
    padding-bottom:4rem;
    #slick-prev-suggestions
    {
      position:absolute;
      top:auto;
      bottom:0;
      right:48px;
      margin:0 auto;
      //transform:rotate(90deg);
    }
    #slick-next-suggestions
    {
      position:absolute;
      top:auto;
      bottom:0;
      right:0;
      margin:0 auto;
      //transform:rotate(90deg);
    }
  }
  .boxed
  {
    margin:0 !important;
    padding:0 !important;
  }
  //margin:4rem 0;
  .property-suggestions__title
  {
    font-weight:600;
    font-size:23px;
    color:$black;
    margin:0;
  }

  .property__info
  {
    flex-direction:column;
  }

  .property__inner
  {
    box-shadow:none;
  }

  .property__thumb,
  .boxed
  {
    width:100% !important;
  }

  .boxed
  {
    flex-direction:column;
    .side
    {
      width:100%;
      padding-left:0;
      padding-bottom:1rem;
      &:before
      {
        display:none;
      }
    }
    .desc
    {
      width:100%;
      padding-right:0;

    }
    hr
    {
      display:none;
    }
    #property__resume
    {

    }
    #property-prices
    {
      display:block;
      width: 100%;
    }
    .property__resume,
    .property-prices-occupancy
    {
      display:inline-block;
      vertical-align: middle;
      width:50%;
      padding:1rem 0;
    }
    .property-prices-total,
    .property-prices-per-night
    {
      display:block;
      width:100%;
    }
  }


  .property__name
  {
    font-size: 18px;
    @include media-breakpoint-up(md)
    {
      font-size: 20px;
    }
  }

  .property__thumb
  {
    height:160px;
    &:before
    {
      display:none;
    }
    .property__thumb-bg
    {
      width:100%;
      margin-right:0;
      border-radius: $border-radius-lg;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    @include media-breakpoint-up(md)
    {
      min-height: 200px;
      height:auto;
    }
  }
  .boxed .desc,
  .boxed .side
  {
    .property__name,
    .property__city,
    .property__rates,
    .property__desc,
    #property-prices
    {
      margin: $grid-gutter-width/5 $grid-gutter-width/3 0 $grid-gutter-width/3;
      width: calc(100% - #{2*$grid-gutter-width/3}) !important;
    }
    .btn-secondary
    {
      margin-top:0;
      margin-bottom:$grid-gutter-width/3;
    }
    #property-prices
    {
      margin-top: 0 !important;
    }
    .property__desc
    {
      font-size:14px;
    }
    .property-prices-total
    {
      font-size: 20px;
    }
  }

}
</style>